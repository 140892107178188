import React from 'react';
import styled, { css } from 'styled-components';
import PortableText from "./portableText";
import CTALink from "./CTALink";
import { imageUrlFor } from "../lib/image-url";

function Hero(props) {
  const { disabled, strapline, minHeight, split, heading, image, category, cta } = props;
  const heroImage = image && imageUrlFor(image).url();
  return !disabled && image ? (
      <HeroElement minHeight={minHeight} full={!split} style={!split ? { backgroundImage: `url(${heroImage})`} : null} className={'Hero'}>
        <HeroContent className={'HeroContent'}>
          {heading && <h1>{heading}</h1>}
          {category && (<Tag><span>{category}</span></Tag>)}
          {strapline && <PortableText blocks={strapline} />}
          {cta && cta.title && <CTALink {...cta} />}
        </HeroContent>
        {split && heroImage && (
            <HeroImage style={{backgroundImage: `url(${heroImage})`}}></HeroImage>
        )}
      </HeroElement>
  ) : null;
}

export default Hero;

const Tag = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 7px;

  span {
    display: flex;
    align-content: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-weight: bolder;
    padding: 2px 7px 5px;
    border-radius: 5px;
    background: #7ab528;

    @media (min-width: 600px) {
      font-size: 14px;
      padding: 4px 8px 8px;
    }
  }
`;

const HeroElement = styled.div`
  position: relative;
  display: flex;
  background: white;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;

  ${({full, minHeight}) => !full && css`
    @media (min-width: 1024px) {
      min-height: ${minHeight ? minHeight : '500px'};
    }
  `}; 

  ${({full, minHeight}) => full && css`
    background-size: cover;
    background-position: center;
    color: white;

    @media (min-width: 1024px) {
        min-height: ${minHeight ? minHeight : '0px'};
      }

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      background: black;
      opacity: 0.5;
      z-index: 0;
    }

    button {
      background: white;
      border-color: white;
    }

    ${HeroContent} {
      max-width: 100%;
      padding: 24px;
      margin: 0 auto;

      @media (min-width: 600px) {
        max-width: 80%;
      }

      @media (min-width: 1024px) {
        padding: 8px 0 32px 0;
      }

      h1, p {
        max-width: 100%;

        @media (min-width: 1024px) {
          max-width: 60%;
        }
      }
    }
  `};

  &:hover {
    box-shadow: 0px 10px 32px -10px rgba(0,0,0,0.1);
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 24px;
  margin: 0 auto;
  z-index: 2;

  @media (min-width: 600px) {
    max-width: 40%;
    padding: 32px 0px 32px 7%;
  }

  h1 {
    font-size: 24px;
    width: 100%;
    line-height: 1;

    @media (min-width: 1024px) {
      font-size: 52px;
    }
  }

  p {
    width: 100%;
    line-height: 1.4;
    font-size: 16px;

    @media (min-width: 1024px) {
      max-width: 80%;
      font-size: 22px;
    }
  }

  button {
    margin-top: 16px;

    @media (min-width: 1024px) {
      margin-top: 32px;
    }
  }
`;

const HeroImage = styled.div`
  display: none;

  @media (min-width: 600px) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 60%;
    background-size: cover;
    background-position: center;
  }

  &:before {
    position: absolute;
    content: '';
    width: 100px;
    height: 200%;
    left: -45px;
    background: white;
    transform: rotate(10deg);
    z-index: 1;
  }
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background: black;
    opacity: 0.3;
  }
`;